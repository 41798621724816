import { useRef, useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { AppUser } from '../../../../../user/model/AppUser';
import { convertFromISO, SimpleSpeedChange } from '../../model/SimpleSpeedChange';
import { fetchUser } from '../../../../../user/UserSlice';
import {
  deleteScheduleById,
  fetchSimpleSpeedChangeData,
} from '../../ScheduleSpeedChangeSlice';
import { ToastContainer, toast } from 'react-toastify';
import ScheduledSimpleSpeedChange from './SimpleSpeedChange';
import CancelModal from '../../../../../common/Cancel/CancelModal';
import Edit from '../../../../../../images/edit-05.svg';
import InfoCircle from '../../../../../../images/error-info-icon.svg';
import Loader from '../../../../../../images/preloading.gif';
import userIcon from '../../../../../../../src/images/user-03.svg';
import calendar from '../../../../../../../src/images/calendar.svg';
import Frequency from '../../../../../../../src/images/frequency.svg';
import Scheduled from '../../../../../../../src/images/calendar-date.svg';
import InProgress from '../../../../../../../src/images/contrast-02.svg';
import Completed from '../../../../../../../src/images/check-green.svg';
import Failed from '../../../../../../../src/images/alert-circle.svg';
import Canceled from '../../../../../../../src/images/x-close.svg';
import { ScheduleSpeedChange, scheduleStatus, scheduleStatusMessage } from '../../model/ScheduleSpeedChange';

const SimpleSpeedChangeFrequency: React.FC = () => {

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const [user, setUser] = useState<AppUser | null>(currentUser);
  const [simpleSpeedChangeInstance, setSimpleSpeedChangeInstance] = useState<SimpleSpeedChange | null>(null);
  const [isSimpleChangeOpen, setIsSimpleChangeOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(false);
  const dropdownRef = useRef<(HTMLDivElement | null)[]>([]); // Allow null values in the ref array
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const hElement = document.getElementById('main-root');
  const scheduleSpeedChangeData = useAppSelector((state) => state.scheduleSpeedChange)
  const simpleSpeedChangeDataArray = scheduleSpeedChangeData.simpleSpeedChangeDataArray
  const selectedAssetRef = useRef<string>('')
  const loading = scheduleSpeedChangeData.loading || scheduleSpeedChangeData.setPointAddressLoading || scheduleSpeedChangeData.getSimpleSpeedChangeDataLoading
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(25)
  const { status, statusLoading } = useAppSelector((state) => state?.ScheduleSpeedStatus);

  useEffect(() => {
    if (!currentUser?.id) {
      dispatch(fetchUser(storedUser?.id));
    }
  }, [currentUser?.id, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedAssetRef.current !== selectedAsset.selectedAssetName) {
      closeModal()
      selectedAssetRef.current = selectedAsset.selectedAssetName ?? ''
    }
  }, [selectedAsset.selectedAssetName]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDropdownOpen !== null && dropdownRef.current[isDropdownOpen] && !dropdownRef.current[isDropdownOpen]?.contains(event.target as Node)) {
        setIsDropdownOpen(null);
        setActiveScheduleIndex(null)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleAddSchedule = () => {
    setIsSimpleChangeOpen(true);
    setSimpleSpeedChangeInstance({
      currentFrequency: '',
      newFrequency: '',
      scheduleDateTime: '',
      description: '',
    });
    setEditMode(false);
  };

  const closeModal = () => {
    setIsSimpleChangeOpen(false);
    setEditMode(false);

    dispatch(
      fetchSimpleSpeedChangeData({
        wellId: selectedAsset?.selectedAssetId ?? '',
        deviceId: storedUser?.id,
        pageNumber: 1,
        pageSize: pageSize
      }),
    );
    setPageNumber(1)
  };

  const handleEditSchedule = (simpleSpeedChangeData: ScheduleSpeedChange) => {
    if (simpleSpeedChangeData.userId === user?.id) {
      setIsDropdownOpen(null);
      setIsSimpleChangeOpen(true);
      setSimpleSpeedChangeInstance(simpleSpeedChangeData.simpleSpeedChanges?.[0] || null);
      setEditMode(true);
    } else {
      toast.error('Only the creator can edit');
    }
  };

  const handleCancelEdit = () => {
    setIsDropdownOpen(null);
    setIsCancelModalOpen(true);
    closeModal();
    hElement && hElement?.classList.add('modal-open');
  };

  // const handleSaveModal = ( ) => {
  //     toast.success('Scheduled speed change successfully changed');
  //     closeModal();
  // };

  const openDropdown = (index: number) => {

    if (isDropdownOpen === index) {
      setIsDropdownOpen(null)
      setActiveScheduleIndex(null)

    }
    else {
      setIsDropdownOpen(index);
      setActiveScheduleIndex(index)
    }
  };

  const handleConfirmCancel = async () => {
    if (activeScheduleIndex === null) return
    const activeSCheduleData = simpleSpeedChangeDataArray[activeScheduleIndex]

    try {
      const response = await dispatch(deleteScheduleById(activeSCheduleData?.id || ''));
      if (response) {

        toast.success('Scheduled speed change successfully canceled', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(
          fetchSimpleSpeedChangeData({
            wellId: selectedAsset?.selectedAssetId ?? '',
            deviceId: storedUser?.id,
            pageNumber: 1,
            pageSize: pageSize,
            onlyStep: false
          }),
        );

        setIsCancelModalOpen(false);
        setPageNumber(1)
        hElement && hElement?.classList.remove('modal-open');
      }
    } catch (error) {
      toast.error('Failed to cancel schedule speed change', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }

  };

  function getStatusMessage(statusCode: number) {
    // Get the key from the scheduleStatus enum
    const statusKey = Object.keys(scheduleStatus).find(
      (key) => scheduleStatus[key as keyof typeof scheduleStatus] === statusCode
    );

    // Use the key to fetch the message from scheduleStatusMessage
    if (statusKey) {
      return scheduleStatusMessage[statusKey as keyof typeof scheduleStatusMessage];
    }

    return ''; // Return undefined if the status code is invalid
  }

  const observer = useRef<IntersectionObserver>();
  const lastSpeedChangeDataRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (scheduleSpeedChangeData.getSimpleSpeedChangeDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && scheduleSpeedChangeData.simpleSpeedChangeTotalCount > simpleSpeedChangeDataArray?.length) {

          const updatedPageNumber = pageNumber + 1
          setPageNumber(updatedPageNumber)
          dispatch(
            fetchSimpleSpeedChangeData({
              wellId: selectedAsset?.selectedAssetId ?? '',
              deviceId: storedUser?.id,
              pageNumber: updatedPageNumber,
              pageSize: pageSize,
              onlyStep: false
            }),
          );

        }
      });
      if (node instanceof HTMLDivElement) observer.current.observe(node);
    },
    [simpleSpeedChangeDataArray?.length],
  );


  return (
    <>
      {isSimpleChangeOpen && (
        <ScheduledSimpleSpeedChange
          isOpen={isSimpleChangeOpen}
          simpleSpeedChange={simpleSpeedChangeInstance}
          onClose={closeModal}
          editMode={editMode}
          scheduleId={activeScheduleIndex !== null ? simpleSpeedChangeDataArray[activeScheduleIndex]?.id || '' : ''}
        />
      )}
      {isCancelModalOpen && (
        <CancelModal
          isCancelModal={isCancelModalOpen}
          onClose={() => { setIsCancelModalOpen(false) }}
          onCancel={handleConfirmCancel}
        />
      )}
      <div className='tab-content text-left'>
        <div className='flex flex-row pb-4'>
          {isSimpleChangeOpen ? null : (
            <div className='card'>
              <div className='header'>
                <div className='flex w-full flex-row'>
                  <div className='basis-3/5'>
                    <span className='scheduleSpeed__title'>Simple Speed Change</span>
                    <div className='pt-3'>
                      Simple speed change allows a user to schedule a one-time speed change to an asset either
                      immediately, or at a later date.
                    </div>
                  </div>
                  <div className='basis-2/5 text-right'>
                    {!editMode && (
                      <button
                        className='scheduleSpeed btn btn-primary add-btn'
                        onClick={handleAddSchedule}
                        disabled={(loading || statusLoading) || (status !== null && status !== undefined && status === scheduleStatus.Pending) || (status !== null && status !== undefined && status === scheduleStatus.InProgress)}
                      >
                        <span className='btn-icon'>+</span> Add speed change
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <div className='body d-flex align-items-center'>
                    <div className='card m-0'>
                      <div className='body'>
                        <p className='loader-scan'>
                          <img src={Loader} alt='Loading ...' className='loader-icon' />
                          Scanning asset ...
                        </p>
                      </div>
                    </div>
                  </div>
                ) :

                  <div style={{ overflowY: 'auto', height: 'calc(100vh - 300px)' }}>
                    {
                      simpleSpeedChangeDataArray?.length ? (
                        simpleSpeedChangeDataArray.map((schData, scheduleIndex) => {

                          const simpleSpeedChangeData = schData?.simpleSpeedChanges?.[0];
                          if (simpleSpeedChangeData)
                            return (
                              <div className='body scheduleSpeed' key={scheduleIndex}
                                ref={simpleSpeedChangeDataArray.length === scheduleIndex + 1 ? lastSpeedChangeDataRef : null}

                              >
                                <div className='scheduleSpeed__card m-0'>
                                  <div className='header'>
                                    <div className='flex w-full flex-row'>
                                      <div className='basis-four'>
                                        <div className='flex w-full flex-row'>
                                          <span className='scheduleSpeed__title two'>Simple Speed Change</span>
                                          <span className={`sm-highlt ${schData?.status ? scheduleStatus[schData?.status] : ''}`}>
                                            {
                                              schData?.status === scheduleStatus.InProgress ?
                                                <img src={InProgress} alt='speed-change' />
                                                : schData?.status === scheduleStatus.Pending ?
                                                  <img src={Scheduled} alt='speed-change' />
                                                  : schData?.status === scheduleStatus.Completed ?
                                                    <img src={Completed} alt='speed-change' />
                                                    : schData?.status === scheduleStatus.Failed ?
                                                      <img src={Failed} alt='speed-change' />
                                                      : schData?.status === scheduleStatus.Cancelled ?
                                                        <img src={Canceled} alt='speed-change' />
                                                        : schData?.status === scheduleStatus.Shutdown ?
                                                          <img src={Failed} alt='speed-change' />
                                                          : ''

                                            }
                                            {getStatusMessage(schData?.status || 0)}

                                          </span>
                                        </div>
                                        <div className='list-inline-items'>
                                          <span>
                                            <img src={userIcon} alt='userIcon' />
                                            {schData?.createdByName}
                                          </span>
                                          <span>
                                            <img src={calendar} alt='calendar' />
                                            {(() => {
                                              const convertedValue = convertFromISO(simpleSpeedChangeData?.scheduleDateTime);
                                              if (convertedValue)
                                                return `${convertedValue.date} - ${convertedValue.time} ${convertedValue.ampm}`;
                                            })()}
                                          </span>
                                          <span>
                                            <img src={Frequency} alt='frequency' />
                                            Initial: {simpleSpeedChangeData?.currentFrequency ?? ''} Hz Target: {simpleSpeedChangeData?.newFrequency} Hz
                                          </span>
                                        </div>
                                      </div>
                                      <div className='basis-one'>
                                        <div className='scheduleSpeed__dropdown-container' ref={(el) => (dropdownRef.current[scheduleIndex] = el)}>
                                          <button
                                            className='scheduleSpeed__dropdown-btn'
                                            onClick={() => openDropdown(scheduleIndex)}
                                          >
                                            <span className='dot'></span>
                                            <span className='dot'></span>
                                            <span className='dot'></span>
                                          </button>
                                          {isDropdownOpen === scheduleIndex && (
                                            <div className='dropdown-options'>
                                              <button>Actions</button>
                                              <button className='btn btn-default' onClick={() => handleEditSchedule(schData)}>
                                                <img src={Edit} alt='icon' className='mr-3' />
                                                Edit
                                              </button>
                                              <button className='btn btn-default' onClick={handleCancelEdit}>
                                                <img src={InfoCircle} alt='icon' className='mr-3' />
                                                Cancel
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='scheduleSpeed__description'>
                                    <div>{simpleSpeedChangeData.description}</div>
                                  </div>
                                </div>
                              </div>
                            )
                        })
                      ) : (
                        <div className='body'>
                          <div className='card m-0'>
                            <div className='scheduleSpeed__body'>
                              <p>No schedule data available.</p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
              }
            </div>
          )}
        </div>
        <ToastContainer position='top-right' autoClose={3000} />
      </div>
    </>
  );
};

export default SimpleSpeedChangeFrequency;
