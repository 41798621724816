import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface UpTimeChartProps {
  data: { date: string; value: number }[];
}

const UpTimeChart: React.FC<UpTimeChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // Convert date strings to Date objects
    const transformedData = data?.map(item => ({
      ...item,
      date: new Date(item.date).getTime() // Convert to timestamp
    }));

    const root = am5.Root.new(chartRef.current!);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingTop: -20, // Remove padding
        paddingBottom: -6,
        paddingLeft: 0,
        paddingRight: 0,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "day", count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );


    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    // Hide x-axis and y-axis labels and grid
    xAxis.get("renderer").labels.template.set("visible", false);
    xAxis.get("renderer").grid.template.set("visible", false);
    yAxis.get("renderer").labels.template.set("visible", false);
    yAxis.get("renderer").grid.template.set("visible", false);

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" })
      })
    );

    series.columns.template.setAll({
      width: 8,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      cornerRadiusBL: 5,
      cornerRadiusBR: 5
    });

    series.columns.template.adapters.add("fill", (fill, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem) {
        return dataItem.dataContext.down ? am5.color("#F97066") : am5.color("#12B76A"); // Red for `down: true`, green otherwise
      }
      return fill;
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem) {
        return dataItem.dataContext.down ? am5.color("#F97066") : am5.color("#12B76A");
      }
      return stroke;
    });


    series.data.setAll(transformedData);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: "90%", height: "40px" }} className='flex items-center' />;
};

export default UpTimeChart;