import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import {
  fetchComments,
  addComment,
  removeComment,
  replyComment,
  removeReply,
  uploadCommentFile,
} from './slices/CommentsSlice';
import DeleteIcon from '../../../../images/icon-delete.svg';
import ReplyIcon from '../../../../images/icon-reply.svg';
import { ReactComponent as SortIcon } from '../../../../images/sort-descending.svg';
import downIcon from '../../../../images/chevron-down.svg';
import { ReactComponent as PlusSquareIcon } from '../../../../images/plus-square.svg';
import { ReactComponent as AttachmentIcon } from '../../../../images/attachment.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import './Comments.scss';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';

interface AttachmentData {
  size: number;
  sizeSuffix: string;
  extension: string;
  fileName: string;
  blobFile: string;
  blobFileUrl: string;
}

const Comments: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showSortPopup, setShowSortPopup] = useState(false);
  // const sortOptions = ['Newest', 'Oldest', 'Unread'];
  const sortOptions = ['Newest', 'Oldest'];
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [newCommentSubject, setNewCommentSubject] = useState('');
  const [newCommentContent, setNewCommentContent] = useState('');
  const [attachment, setAttachment] = useState<AttachmentData | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [replyContent, setReplyContent] = useState('');
  const [activeReplyId, setActiveReplyId] = useState<any>(null);
  const [showValidation, setShowValidation] = useState(false);
  
  const { comments, commnetsLoading, pagination } = useAppSelector((state) => state.Comments);

  // Parameters for fetchComments
  const [assetId, setAssetId] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('Newest');

  // Map sort options to query parameter values
  const sortMapping: Record<string, string> = {
    Newest: 'desc',
    Oldest: 'asc',
    Unread: 'unread',
  };

  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetId = selectedWellName && JSON.parse(selectedWellName || '');
    setAssetId(selectedAssetId?.assetId);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (assetId) {
        dispatch(
          fetchComments({
            assetId: assetId,
            SearchQuery: searchTerm,
            SortOrder: sortOrder,
            SortBy: 'createdAt',
            PageNumber: 1,
            PageSize: 10,
          }),
        );
      }
    }, 500)
    return () => clearTimeout(timeoutId);
  }, [dispatch, assetId, searchTerm, sortOrder]);

  const observer = useRef<IntersectionObserver | null>(null);
  
  const lastDocumentRef = useCallback((node: HTMLDivElement | null) => {
    if (commnetsLoading || !pagination.hasNextPage) return;
  
    if (observer.current) observer.current.disconnect();
  
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && pagination.hasNextPage) {
         // Calculate the next page number based on the current data length
      const nextPageNumber = pagination.pageNumber + 1;
        dispatch(
          fetchComments({
            assetId: assetId,
            SearchQuery: searchTerm,
            SortOrder: sortOrder,
            SortBy: 'createdAt',
            PageNumber: nextPageNumber,
            PageSize: 10,
          }),
        );
      }
    });
  
    if (node) observer.current.observe(node);
  }, [pagination.hasNextPage, commnetsLoading, searchTerm, sortOrder, dispatch]);

  const handleNewCommentToggle = () => {
    setIsCommentOpen(!isCommentOpen);
    setShowValidation(false);
    setNewCommentSubject('');
    setNewCommentContent('');
  };

  
  const handleAttachmentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
  
      try {
        const result = await dispatch(uploadCommentFile(selectedFile)).unwrap();
        console.log('File uploaded successfully:', result);
  
        // Store the API response in the `attachment` state
        setAttachment(result.data); // Use `result.data` based on your response structure
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  

  const handleSubmit = async () => {
    if (newCommentSubject.trim() && newCommentContent.trim()) {
  
      // Use the updated attachment state
      const attachmentData = attachment
        ? [
            {
              size: attachment.size,
              sizeSuffix: attachment.sizeSuffix,
              extension: attachment.extension,
              fileName: attachment.fileName,
              blobFile: attachment.blobFile,
              blobFileUrl: attachment.blobFileUrl,
            },
          ]
        : [];
  
      await dispatch(
        addComment({
          assetId: assetId,
          subject: newCommentSubject,
          content: newCommentContent,
          attachments: attachmentData,
        })
      ).then(() => {
        setNewCommentSubject('');
        setNewCommentContent('');
        setAttachment(null);
        setIsCommentOpen(false);
  
        // Fetch comments again to include the new comment
        dispatch(
          fetchComments({
            assetId: assetId,
            SearchQuery: searchTerm,
            SortOrder: sortOrder,
            SortBy: 'createdAt',
            PageNumber: 1,
            PageSize: 10,
          })
        );
      });
    }
  
    if (!newCommentSubject.trim() || !newCommentContent.trim()) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
  };
  
  
  const handleCancel = () => {
    setIsCommentOpen(false);
    setAttachment(null);
    setShowValidation(false);
    setNewCommentSubject('');
    setNewCommentContent('');
  };
  
  const handleAttachmentClick = () => {
    const fileInput = document.getElementById('attachment-input') as HTMLInputElement;
    fileInput.click();
  };

  const handleDeleteComment = (id: any) => {
    dispatch(removeComment(id)).then(() => {
      dispatch(fetchComments({ assetId, SearchQuery: searchTerm, SortOrder: sortOrder, SortBy: 'createdAt', PageNumber: 1, PageSize: 10 }));
    }).catch((error) => {
      console.error('Failed to delete reply:', error);
  });
  };

  const handleReplyComment = (commentId: any) => {
    if (replyContent.trim()) {
      const payload = {
        commentId,
        content: replyContent,
        attachments: [], // Add attachment data here if applicable
    };
      dispatch(replyComment(payload)).then(() => {
        setReplyContent('');
        setActiveReplyId(null);
        dispatch(fetchComments({ assetId, SearchQuery: searchTerm, SortOrder: sortOrder, SortBy: 'createdAt', PageNumber: 1, PageSize: 10 }));
      }).catch((error) => {
        console.error('Failed to post reply:', error);
    });
    }
  };

  const handleDeleteReply = (commentId: any, replyId: any) => {
    dispatch(removeReply({ commentId, replyId })).then(() => {
      dispatch(fetchComments({ assetId, SearchQuery: searchTerm, SortOrder: sortOrder, SortBy: 'createdAt', PageNumber: 1, PageSize: 10 }));
    }).catch((error) => {
      console.error('Failed to delete reply:', error);
  });
  };

  const handleFilterOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn') return;
    if (showSortPopup) {
      setShowSortPopup(false);
    }
  };

  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleSortSelect = (order: string) => {
    const querySortOrder = sortMapping[order];
    setSortOrder(querySortOrder);
    setShowSortPopup(false);
  };

  return (
    <div className='comments-wrapper'>
      <div className='search-comment-sort-container'>
        <div className='search-container flex flex-row w-100'>
          <div className='w-80'>
            <label className='relative block'>
              <span className='sr-only'>Search</span>
              <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 '>
                  <path
                    fillRule='evenodd'
                    d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </span>
              <input
                className='custom-text-input'
                placeholder='Search'
                type='text'
                name='search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>
        <div className='comment-sort-container'>
          <div className='comment-container'>
            <button
              id='comment-btn'
              type='button'
              className='flex items-center justify-center h-[48px] py-2 px-4 cursor-pointer border border-[#4a5463] text-white rounded-lg'
              onClick={handleNewCommentToggle}
            >
              {' '}
              <PlusSquareIcon />
              New comment
            </button>
          </div>

          <div className='sort-container'>
            <div
              id='sort-btn'
              style={{
                border: showSortPopup
                  ? '1px solid var(--Dark-mode-400, #4A5463)'
                  : '1px solid var(--Dark-mode-400, #4A5463)',
                background: showSortPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowSortPopup(!showSortPopup);
              }}
            >
              <SortIcon />
              Sort
              <img src={downIcon} className={!showSortPopup ? '' : 'rotate-180'} alt='upIcon' />
            </div>

            {showSortPopup && (
              <div className='comments-sort-modal-container' ref={filterRef}>
                <div className='comments-sort-modal-header'>Sort by</div>
                <div className='comments-sort-modal-body'>
                  {sortOptions.map((order) => (
                    <div key={order} className='sort-option-list' onClick={() => handleSortSelect(order)}>
                      {order}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isCommentOpen && (
        <div className='new-comment-box'>
          <h3 className='comment-title'>New Comment</h3>
          <input
            type='text'
            placeholder='Subject'
            className={showValidation && !newCommentSubject.trim() ? 'invalid subject' : 'subject'}
            value={newCommentSubject}
            onChange={(e) => setNewCommentSubject(e.target.value)}
          />
          <textarea
            placeholder='Comment Description'
            className={showValidation && !newCommentContent.trim() ? 'invalid description' : 'description'}
            rows={4}
            value={newCommentContent}
            onChange={(e) => setNewCommentContent(e.target.value)}
          ></textarea>
          <div className='footer-box'>
            <div className='attachment-container'>
              <button type='button' className='attachment' onClick={handleAttachmentClick}>
                <AttachmentIcon />
              </button>

              <input type='file' onChange={handleAttachmentChange} id='attachment-input' style={{ display: 'none' }} />
              {attachment && (
                <div className='attachment-preview'>
                  <span className='attachment-name'>{attachment.fileName}</span>
                </div>
              )}
            </div>
            <div className='action-buttons'>
              <button type='button' className='cancel' onClick={handleCancel}>
                Cancel
              </button>
              <button type='button' className='submit' onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='comments-list-container'>
        {commnetsLoading ? (
          <div className='flex items-center justify-center w-100 chart-loader'>
            <Loader />
          </div>
        ) : (
          <div>
            {comments && comments.length > 0 ? (
              comments?.map((comment, index) => (
                <div key={comment.id} ref={index === comments.length - 1 ? lastDocumentRef : null} className='border-b border-[#4A5463] mb-4 pb-4'>
                  <h3 className='text-[18px] mb-4'>{comment.subject}</h3>
                  <div className='flex text-[14px] justify-between mb-4'>
                    <div className='text-[#00789e]'>{comment.createdByName}</div>
                    <div className='flex w-[200px] text-white text-right justify-end'>
                      {ConvertTimeToLocal(comment?.createdAt)}
                    </div>
                  </div>
                  <div className='flex text-[14px] justify-between mb-4'>
                    <p className='mb-2 text-[14px] max-w-[60%]'>{comment.content}</p>
                    {/* {comment?.active ? <span className='w-[12px] h-[12px] rounded-full bg-red-500 mr-2'></span> : null} */}
                  </div>

                  {comment.attachments && comment.attachments.length > 0 && (
                    <div className='comment-attachments'>
                      <ul>
                        {comment.attachments.map((attachment, index) => (
                          <li key={index} className='comment-attachment-link'>
                            {/* <a
                              href={URL.createObjectURL(new Blob([attachment.blobFileUrl], { type: 'application/pdf' }))}
                              download={attachment.fileName}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {attachment.fileName}
                            </a> */}
                            <button
                              onClick={() => {
                                const link = document.createElement('a');
                                link.href = attachment.blobFileUrl;
                                link.download = attachment.fileName;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            >
                              {attachment.fileName}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div>
                    <button type='button' onClick={() => setActiveReplyId(comment.id)} className='border-none p-2 mr-2 cursor-pointer'>
                      <img src={ReplyIcon} alt='Reply Icon' className='Delete-icon' />
                    </button>
                    <button
                      type='button'
                      className='border-none p-2 cursor-pointer'
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      <img src={DeleteIcon} alt='Delete Icon' className='Delete-icon' />
                    </button>
                  </div>
                  {activeReplyId === comment.id && (
                    <div className='reply-comments'>
                      <textarea
                        placeholder='Reply'
                        value={replyContent}
                        rows={1}
                        onChange={(e) => setReplyContent(e.target.value)}
                      />
                      {/* <button style={{color: '#fff', border: '1px solid'}} onClick={() => handleReplyComment(comment.id)}>Submit</button> */}
                        <div className='action-buttons'>
                          <button type='button' className='cancel' onClick={() => setActiveReplyId(null)}>
                            Cancel
                          </button>
                          <button type='button' className='submit' onClick={() => handleReplyComment(comment.id)}>
                            Submit
                          </button>
                        </div>
                    </div>
                  )}

                  {comment.replies && comment.replies.length > 0 && (
                    <div className='pl-8 pt-5'>
                      {comment.replies.map((reply: any) => (
                        <div key={reply.id}>
                          <div className='flex text-[14px] justify-between mb-4'>
                            <div className='text-[#00789e]'>{reply.createdByName}</div>
                            <div className='flex w-[200px] text-white text-right justify-end'>
                            {ConvertTimeToLocal(reply?.createdAt)}
                            </div>
                          </div>
                          <div className='flex text-[14px] justify-between mb-4'>
                            <p className='mb-2 text-[14px] max-w-[70%]'>{reply.content}</p>
                          </div>
                          <div>
                            <button type='button' onClick={() => handleDeleteReply(comment.id, reply.id)} className='border-none p-2 cursor-pointer'>
                              <img src={DeleteIcon} alt='Delete Icon' className='Delete-icon' />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className='flex flex-column items-center justify-center no-data-found'>
                <NoData heading='No data found' />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comments;
