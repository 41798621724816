import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { slugify } from '../utilities/Slugify';

interface Tab {
  label: string;
  component: React.ComponentType;
  disabled?: boolean;
}

interface TabsProps {
  initialTab: {
    tab: string;
  };
  tabs: Tab[];
  value: string | undefined;
}

const TabWithUrlComponent: React.FC<TabsProps> = ({ initialTab, tabs, value }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const storedTabIndex = sessionStorage.getItem('activeTabIndex');
  const defaultTabIndex = 0;
  const getValidTabIndex = (index: number | null) => {
    if (index !== null && !isNaN(index) && index >= 0 && index < tabs.length && !tabs[index].disabled) {
      return index;
    }
    return tabs.findIndex((tab) => tab.label === initialTab.tab && !tab.disabled) || defaultTabIndex;
  };

  const [activeTabIndex, setActiveTabIndex] = useState(
    getValidTabIndex(storedTabIndex !== null ? parseInt(storedTabIndex, 10) : null)
  );
  const handleTabClick = (index: number) => {
    if (tabs[index].disabled) {
      return;
    }
    sessionStorage.setItem('activeTabIndex', index.toString());
    setActiveTabIndex(index);
  };

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('tab');
    if (currentTabFromQuery) {
      const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
      if (tabIndex !== -1 && storedTabIndex !== null) {
        if (Number(storedTabIndex) !== Number(tabIndex)) {
          setActiveTabIndex(tabIndex);
        }
      }
    }
  }, [location.search, tabs]);

  useEffect(() => {
    const validIndex = activeTabIndex >= 0 && activeTabIndex < tabs?.length && !tabs[activeTabIndex]?.disabled
      ? activeTabIndex
      : defaultTabIndex;

    const newTabSlug = slugify(tabs[validIndex].label);
    const currentTabSlug = new URLSearchParams(location.search).get('tab');
    // Only navigate if the tab slug in the URL is different
    if (location.pathname !== '/layout/well-control-room' && currentTabSlug !== newTabSlug) {
      navigate(`${location.pathname}?tab=${newTabSlug}`);
    }
  }, [activeTabIndex, navigate, location.pathname, tabs, defaultTabIndex]);

  return (
    <>
      <div className='justify-between  ui-button-toolbar '>
        <div className='flex flex-grow'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button ${index === activeTabIndex
                ? 'ui-button-toolbar-button-active semi-bold'
                : 'semi-bold'
                } ${tab.disabled ? 'opacity-50' : ''
                }`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>

      </div>
      <div className='tab-content'>
        {React.createElement(tabs[activeTabIndex]?.component || tabs[defaultTabIndex].component, {
          value,
        } as React.Attributes)}
      </div>
    </>
  );
};

export default TabWithUrlComponent;
