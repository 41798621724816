import React, { memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { HealthScoreChartProps } from '../constants/healthScore.constants';
import { useAppSelector } from '../../../hooks/storeHooks';
// import Info from '../../../images/info-circle-yellow.svg';

const HealthChart: React.FC<HealthScoreChartProps> = ({
    chartID,
    chartRef,
    classValue,
    minValue,
    maxValue,
    startAngle,
    endAngle,
    bandsData,
    innerCircleEndvalue,
    spacingValue,
    value,
    root,
}) => {
    const { healthScore } = useAppSelector((state) => state.healthScore)


    useEffect(() => {
        setTimeout(() => {
            if (chartRef.current) {

                // Dispose of previous root if it exists
                if (root.current) {
                    root.current.dispose();
                    root.current = null;
                }

                root.current = am5.Root.new(chartRef.current);

                root.current.setThemes([am5themes_Animated.new(root.current)]);

                const chart = root.current.container.children.push(
                    am5radar.RadarChart.new(root.current, {
                        panX: false,
                        panY: false,
                        startAngle: startAngle,
                        endAngle: endAngle,
                        radius: am5.percent(90), // Set radius to 80% of the container
                        paddingBottom: 60, // Add padding at the bottom
                    })
                );

                const axisRenderer = am5radar.AxisRendererCircular.new(root.current, {
                    strokeOpacity: 0.1,
                    minGridDistance: 30
                });

                axisRenderer.ticks.template.setAll({
                    visible: false,
                    strokeOpacity: 0.5
                });

                axisRenderer.grid.template.setAll({
                    visible: false
                });

                const axis = chart.xAxes.push(
                    am5xy.ValueAxis.new(root.current, {
                        maxDeviation: 0,
                        min: minValue,
                        max: maxValue,
                        strictMinMax: true,
                        renderer: axisRenderer,

                    })
                );


                // Set label properties to display horizontally and avoid wrapping
                axis.get('renderer').labels.template.setAll({
                    fill: am5.color('#fff'),
                    rotation: 0, // Horizontal labels
                    textAlign: 'center', // Centered horizontally
                    verticalCenter: 'middle', // Centered vertically
                    maxWidth: 100, // Adjust as needed
                    fontSize: "12px", // Smaller font size if necessary
                    fontWeight: "400", // Adjust weight if needed
                    visible: false
                });


                // create inner gray band.
                const createInnerRange = (start: number, end: number, color: am5.Color) => {
                    const rangeDataItem: any = axis.makeDataItem({
                        value: start,
                        endValue: end
                    });

                    axis.createAxisRange(rangeDataItem);

                    rangeDataItem.get("axisFill").setAll({
                        visible: true,
                        fill: color,
                        fillOpacity: 1,
                        innerRadius: -40,
                        //stroke: color,
                        //  strokeOpacity: 0.8,
                        //strokeWidth: 1
                    });

                    rangeDataItem.get("tick").setAll({
                        visible: false
                    });
                }

                createInnerRange(1, innerCircleEndvalue ?? 100, am5.color(0x384252));

                const labelColor = (value <= 15) ? '#F97066' : (value > 15 && value <= 85) ? '#FDB022' : '#12B76A'

                // band creation
                am5.array.each(bandsData, function (data, index) {
                    const axisRange: any = axis.createAxisRange(axis.makeDataItem({}));
                    axisRange.setAll({
                        value: data.lowScore + (index > 0 ? spacingValue : 0), // Add gap if it's not the first range
                        endValue: data.highScore,
                        startAngle: 45, // Rotation starts at 45 degrees
                        endAngle: 90,   // Rotation ends at 90 degrees
                    });

                    axisRange.get("axisFill").setAll({
                        visible: true,
                        fill: am5.color(data.color),
                        fillOpacity: 1,
                        innerRadius: -10,
                        gap: 10,
                        cornerRadius: 30,

                    });

                    axisRange.get("label").setAll({
                        textType: "regular",
                        location: 1,
                        // location: index === bandsData?.length - 1 ? 1 : 1,
                        text: data.highScore,
                        visible: true,
                        centerX: am5.p50,
                        centerY: am5.p50,
                        radius: 20
                    });

                    if (index === 0) {
                        const axisRange1 = axis.createAxisRange(axis.makeDataItem({}));

                        axisRange1.setAll({
                            value: data.lowScore,
                            endValue: data.highScore
                        });

                        axisRange1.get("axisFill").setAll({
                            visible: true,
                            fill: am5.color(data.color),
                            fillOpacity: 1,
                            innerRadius: -10,
                            gap: 10,
                            cornerRadius: 25,
                        });

                        // Set the high label (on the right side of the range)
                        axisRange1.get("label").setAll({
                            textType: "regular",
                            location: 0.1,
                            text: data.lowScore,
                            visible: true,
                            centerX: am5.p50,
                            centerY: am5.p50,
                            radius: 20
                        });
                    }

                    const bulletDataItem: any = axis.makeDataItem({
                        value: value,// Set this to the specific value where you want the bullet
                        location: 0.5
                    });

                    bulletDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                        sprite: am5.Circle.new(root.current, {
                            radius: 9, // Adjust the radius as needed
                            fill: am5.color('#384252'), // Bullet fill color
                            stroke: am5.color(labelColor), // Bullet border color
                            strokeWidth: 2, // Bullet border width
                            centerX: am5.percent(84), // Experiment with setting this to 0 instead of 50
                            centerY: am5.percent(50),
                            // dx: 0, // Horizontal positioning offset
                            // dy: 0  // Vertical positioning offset
                        }),
                    }));

                    axis.createAxisRange(bulletDataItem);
                });


                // Add clock hand
                const createHand = (value: number | undefined, topWidth: number, bottomWidth: number, pinRadius: number, radius: number, color: am5.Color) => {
                    const handDataItem: any = axis.makeDataItem({
                        value: value
                    });

                    const hand = handDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                        sprite: am5radar.ClockHand.new(root.current, {
                            topWidth: topWidth,
                            pinRadius: am5.percent(pinRadius),
                            radius: am5.percent(radius),
                            bottomWidth: bottomWidth,
                            //bottomWidth: 200,
                            innerRadius: am5.percent(72),

                        })
                    }));

                    hand.get("sprite").pin.setAll({
                        forceHidden: false,
                        fill: color,
                        fillOpacity: 1,
                    });

                    hand.get("sprite").hand.setAll({
                        fill: color,
                        fillOpacity: 1,
                    });


                    axis.createAxisRange(handDataItem);

                    return hand;
                }
                createHand(50, 0, 0, 70, 78, am5.color(0x5D6675));
                createHand(50, 0, 0, 70, 75, am5.color(0x001023));
                const hand2 = createHand(value, 0, 0, 61, 90, am5.color(0x4A5463));
                const hand1 = createHand(value, 0, 20, 60, 83, am5.color(0x001023));


                hand1.get("sprite").dataItem.animate({
                    key: "value",
                    to: value,
                    duration: 800,
                    easing: am5.ease.out(am5.ease.cubic)
                });
                hand2.get("sprite").dataItem.animate({
                    key: "value",
                    to: value,
                    duration: 800,
                    easing: am5.ease.out(am5.ease.cubic)
                });

                const label = chart.radarContainer.children.push(am5.Label.new(root.current, {
                    fill: am5.color(0xffffff),
                    centerX: am5.percent(50),
                    textAlign: "center",
                    centerY: am5.percent(65),
                    fontSize: "39px",
                    fontWeight: "700"
                }));
                label.set("text", Math.round(value ?? 0).toString());

                const label2 = chart.radarContainer.children.push(am5.Label.new(root.current, {
                    fill: am5.color(labelColor),
                    centerX: am5.percent(50),
                    textAlign: "center",
                    centerY: am5.percent(0),
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "-10px"
                }));
                label2.set("text", `${healthScore?.healthScoreStatusName}`);

                // Create a legend
                const legend = chart.children.push(
                    am5.Legend.new(root.current, {
                        contentAlign: "center", // Center the content of the legend itself
                        centerX: am5.p50,  // Center horizontally
                        x: am5.p50,        // Align to the center
                        y: am5.percent(137), // Move it to the bottom
                        // y: am5.percent(110), // Move it to the bottom for modal window
                        centerY: am5.p100, // Anchor the legend at the bottom
                        layout: root.current.gridLayout, // Layout for horizontal alignment    layout: root.current.horizontalLayout, // Arrange items in a horizontal layout
                    })
                );

                // Set the legend data (name and value)
                legend.data.setAll([
                    {
                        name: "Last updated on:",
                        value: `${healthScore?.trendDisplayText}`,
                    },

                ]);

                // Set the legend labels
                legend.labels.template.setAll({
                    text: "{name}",
                });
                // Set the legend value labels
                legend.valueLabels.template.setAll({
                    text: "{value}",
                });
                legend.labels.template.set('fill', am5.color('#F7F9F9'));
                legend.valueLabels.template.set('fill', am5.color('#F7F9F9'));

                legend.itemContainers.template.setAll({
                    centerX: am5.p50, // Align the item container horizontally at the center
                    x: am5.p50,       // Center position for the item container
                    layout: root.current.horizontalLayout, // Arrange items horizontally
                    width: am5.percent(100) // Allow item containers to take the full width of the parent
                });

                legend.labels.template.setAll({
                    textAlign: "right",    // Align the text inside the label to the left
                    centerX: am5.p0,      // Align the label text horizontally within its container
                    x: am5.p0,            // Position the label text at the start of the item container
                    width: am5.percent(50), // Use 50% of the container width for the name label
                    wrap: true            // Enable text wrapping within the defined width
                });

                legend.valueLabels.template.setAll({
                    textAlign: "left",    // Align the value text to the right
                    centerX: am5.p0,       // Align the value text horizontally within its container
                    x: am5.p50,            // Position the value text in the second half of the container
                    width: am5.percent(50), // Use the remaining 50% for the value label
                    wrap: true             // Enable text wrapping for value labels
                });
                // Clean up chart when the component unmounts
                return () => {
                    root.current.dispose();
                };
            }
        }, 500);
    }, []);


    return (
        <>
            <div id={`${chartID}`} ref={chartRef} className={`${classValue} health-score-chart-wrapper`}></div>
        </>
    );
};

export default memo(HealthChart);