import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addAssetToWatchListAPI, GetAssetList, removeAssetFromWatchListAPI } from './AllWellService';
import {  AssetApiResponse, AssetData } from '../../interfaces/AssetList.interface';



interface ControlRoomWellsState {
    AllWellAssetList: AssetData;
    loading: boolean;
    error: string | null;
    AllWellAssetListTimeSeries: AssetData;
    allWellLoading: boolean;
    allWellTimeseriesLoading: boolean;
}

const initialState: ControlRoomWellsState = {
    loading: false,
    error: null,
    AllWellAssetList: {} as AssetData,
    AllWellAssetListTimeSeries:  {} as AssetData,
    allWellLoading: false,
    allWellTimeseriesLoading: false,
};

export const fetchAssetList = createAsyncThunk(
    'controlRoomWells/fetchAssetList',
    async ({ searchQuery, watchListId, includeTimeseriesFields, pageSize, pageNumber, signal }: {
        pageSize: number,
        pageNumber: number,
        includeTimeseriesFields: boolean,
        searchQuery?: string,
        watchListId?: string,
        signal?: AbortSignal 
    }) => {
        return await GetAssetList(pageSize, pageNumber, includeTimeseriesFields, searchQuery, watchListId, signal);
    }
);

export const fetchAssetListTimeSeries = createAsyncThunk(
    'controlRoomWells/fetchAssetListTimeSeries',
    async ({ searchQuery, watchListId, includeTimeseriesFields, pageSize, pageNumber, signal }: {
        pageSize: number,
        pageNumber: number,
        includeTimeseriesFields: boolean,
        searchQuery?: string,
        watchListId?: string,
        signal?: AbortSignal 
    }) => {
        return await GetAssetList(pageSize, pageNumber, includeTimeseriesFields, searchQuery, watchListId, signal);
    }
);

export const addAssetToWatchList = createAsyncThunk(
    'controlRoomWells/addAssetToWatchList',
    async (assetId: string) => {
        return await addAssetToWatchListAPI(assetId);
    }
);

export const removeAssetFromWatchList = createAsyncThunk(
    'controlRoomWells/removeAssetFromWatchList',
    async (assetId: string) => {
        return await removeAssetFromWatchListAPI(assetId);
    }
);

const controlRoomWellsSlice = createSlice({
    name: 'controlRoomWells',
    initialState,
    reducers: {
        resetControlRoomWells: (state) => {
            state.loading = false;
            state.error = null;
            state.AllWellAssetList = {} as AssetData;
            state.AllWellAssetListTimeSeries = {} as AssetData; 
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAssetList.pending, (state) => {
            state.loading = true;
            state.allWellLoading = true;
        });
        builder.addCase(fetchAssetList.fulfilled, (state, action: PayloadAction<AssetApiResponse>) => {
            state.AllWellAssetList = {
                ...state.AllWellAssetList,
                ...action.payload.data,
                results: state.AllWellAssetList.results ? [...state.AllWellAssetList.results, ...action.payload.data.results] : action.payload.data.results
            };
            state.loading = false;
            state.allWellLoading = false;
        });
        builder.addCase(fetchAssetList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
            state.allWellLoading = false;
        });

        builder.addCase(fetchAssetListTimeSeries.pending, (state) => {
            state.allWellTimeseriesLoading = true;
        });
        builder.addCase(fetchAssetListTimeSeries.fulfilled, (state, action: PayloadAction<AssetApiResponse>) => {
            state.AllWellAssetListTimeSeries = {
                ...state.AllWellAssetListTimeSeries,
                ...action.payload.data,
                results: state.AllWellAssetListTimeSeries.results ? [...state.AllWellAssetListTimeSeries.results, ...action.payload.data.results] : action.payload.data.results
            };
            state.allWellTimeseriesLoading = false;
        });
        builder.addCase(fetchAssetListTimeSeries.rejected, (state, action) => {
            state.error = action.payload as string;
            state.allWellTimeseriesLoading = false;
        });
   
        builder.addCase(addAssetToWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addAssetToWatchList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(addAssetToWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(removeAssetFromWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(removeAssetFromWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(removeAssetFromWatchList.fulfilled, (state) => {
            state.loading = false;
        });
    },
});

export default controlRoomWellsSlice.reducer;
export const selectControlRoomWells = (state: { controlRoomWells: ControlRoomWellsState }) => state.controlRoomWells;
export const { resetControlRoomWells } = controlRoomWellsSlice.actions;