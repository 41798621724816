import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AssetListSupport.scss';
import { ReactComponent as SortIconDefault } from '../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../images/Chevron-down-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { AppUser } from '../../user/model/AppUser';
import { clearAssetSupportList, clearAssetSupportListError, fetchAssetTimeSeries, setFilterTerm, supportAssetsList } from '../supportSlice';
import { CalculateRunDays, ConvertTimeToLocal } from '../../../utilities/CommonFunctions';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';
import { updateAssetList, updateSelectedAsset } from '../../navigation/AssetGroupsSlice';
import { getNumericRunDays, getValueByKey } from '../../dashboard/components/asset-location/AssetList';

interface AssetEventSchedule {
    wellName: string;
    lastGoodScan: string;
    alarms: string[];
    runStatus: string;
    comms: string;
    speed: string;
    current: string;
    motorTemp: string;
    intakePressure: string;
    tubingPressure: string;
    vibration: string;
    driveLoad: string;
    installationDate: string;
    [key: string]: string | string[];
}

type SortableKeys = keyof AssetEventSchedule;
const AssetList: React.FC = () => {
    const navigate = useNavigate();
    const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
    const storedUserData = localStorage.getItem('loggedInUser');
    const activeTab = sessionStorage.getItem('activeTabIndex');
    const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
    const initialUserState = loggedInUser || storedUser || undefined;
    const dispatch = useAppDispatch();
    const assetsLists = useAppSelector((state) => state?.SupportSlice?.assetsLists);
    const listLoading = useAppSelector((state) => state?.SupportSlice?.assetListLoading);
    const assetsListsTimeSeries = useAppSelector((state) => state?.SupportSlice?.assetListTimeSeries);
    const assetTimeSeriesLoading = useAppSelector((state) => state?.SupportSlice?.assetTimeSeriesLoading);
    const assetSupportListErrorMessage = useAppSelector((state) => state?.SupportSlice?.assetSupportListErrorMessage);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
        key: 'wellName',
        direction: 'asc',
    });
    const filterTerm = useAppSelector((state) => state?.SupportSlice?.filterTerm);
    const abortController = useRef<AbortController | null>(null);
    useEffect(() => {
        setLoading(true);
        dispatch(setFilterTerm(""));
        abortController.current = new AbortController();
        dispatch(clearAssetSupportList());
        setTimeout(() => {


            dispatch(supportAssetsList({
                searchQuery: encodeURIComponent(filterTerm),
                pageNumber: 1,
                pageSize: 25,
                signal: abortController?.current?.signal
            })).then(() => {
                setTimeout(() => {
                    dispatch(fetchAssetTimeSeries({
                        searchQuery: encodeURIComponent(filterTerm),
                        pageNumber: 1,
                        pageSize: 25,
                        signal: abortController?.current?.signal
                    })).then(() => {
                        setLoading(false)
                    })
                }, 500);
                setLoading(false);
            })

        },);


        return () => {
            if (abortController.current) {
                abortController.current.abort();
            }
        }
    }, [dispatch]);

    const sortedAssetEventSchedule = useMemo(() => {
        const sortableItems = !assetTimeSeriesLoading && assetsListsTimeSeries?.results?.length ? [...assetsListsTimeSeries?.results || []] : [...assetsLists?.results || []];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                const aValue = getValueByKey(a as unknown as AssetEventSchedule, sortConfig.key);
                const bValue = getValueByKey(b as unknown as AssetEventSchedule, sortConfig.key);
                // If the sort key is 'installationDate', calculate run days and compare
                if (sortConfig.key === 'installationDate') {
                    const aRunDays = getNumericRunDays(aValue);
                    const bRunDays = getNumericRunDays(bValue);
                    return (aRunDays - bRunDays) * (sortConfig.direction === 'asc' ? 1 : -1);
                }
                return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * (sortConfig.direction === 'asc' ? 1 : -1);
            });
        }
        return sortableItems;
    }, [assetsLists?.results.length, assetsListsTimeSeries?.results.length, sortConfig])

    const observer = useRef<IntersectionObserver>();
    const assetlistTable = document.getElementById('assetlistTable');
    const lastDocumentElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (loading || !assetsLists?.hasNextPage || assetTimeSeriesLoading) return;
            if (observer.current) observer.current.disconnect();
            if (assetSupportListErrorMessage) {
                if (assetlistTable) {
                    assetlistTable.scrollTop = assetlistTable.scrollTop - 100;
                }
                dispatch(clearAssetSupportListError());
            }

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0]?.isIntersecting && initialUserState && (assetsLists?.hasNextPage && assetsListsTimeSeries?.hasNextPage)) {
                    setLoading(true);
                    if (abortController.current) {
                        abortController.current.abort();
                    }
                    abortController.current = new AbortController();
                    dispatch(supportAssetsList({
                        searchQuery: encodeURIComponent(filterTerm),
                        pageNumber: assetsLists?.nextPageNumber || 1,
                        pageSize: 25,
                        signal: abortController?.current?.signal
                    }))
                        .then(() => {
                            setLoading(false);
                        });

                    setTimeout(() => {
                        dispatch(fetchAssetTimeSeries({
                            searchQuery: encodeURIComponent(filterTerm),
                            pageNumber: assetsListsTimeSeries?.nextPageNumber || 1,
                            pageSize: 25,
                            signal: abortController?.current?.signal
                        }))
                            .then(() => {
                                setLoading(false);
                            });
                    }, 500);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, assetsLists?.pageNumber, assetsListsTimeSeries?.pageNumber, initialUserState],
    );

    const useDebouncedEffect = (effect: React.EffectCallback, deps: any[], delay: number) => {
        const firstRender = useRef(true);

        useEffect(() => {
            if (firstRender.current) {
                firstRender.current = false;
                return;
            }

            const handler = setTimeout(() => {
                effect();
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [...deps, delay]);
    };

    useDebouncedEffect(() => {
        setLoading(true);
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        dispatch(clearAssetSupportList())
        setTimeout(() => {
            if (abortController.current) {
                dispatch(supportAssetsList({
                    searchQuery: encodeURIComponent(filterTerm),
                    pageNumber: 1,
                    pageSize: 25,
                    signal: abortController?.current?.signal
                })).then(() => {
                    setTimeout(() => {
                        dispatch(fetchAssetTimeSeries({
                            searchQuery: encodeURIComponent(filterTerm),
                            pageNumber: 1,
                            pageSize: 25,
                            signal: abortController?.current?.signal
                        })).then(() => {
                            setLoading(false);
                        });
                    }, 500);
                    setLoading(false);
                })
            }
        },);


    }, [filterTerm], 0);

    const requestSort = (key: SortableKeys) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig?.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleWellName = (wellName: string, assetID: string) => {
        window.scrollTo(0, 0);
        if (activeTab !== '0') {
            sessionStorage.setItem('activeTabIndex', '0');
        }

        const groupList = sortedAssetEventSchedule?.map((well) => ({
            assetId: well?.assetID,
            assetName: well?.wellName,
            industryApplicationId: 4,
        }));

        const selectedAsset = {
            assetName: wellName,
            assetGroupName: 'Well Groups',
            assetId: assetID,
            searchString: undefined,
        };
        if (selectedAsset) {
            sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
        }

        if (groupList) {
            sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
        }

        dispatch(updateSelectedAsset(selectedAsset));
        dispatch(updateAssetList(groupList));
        navigate(`/layout/assets/?tab=details&wellName=${wellName}`);
    };


    return (
        <div className='w-100 p-5 pt-3'>
            <div className={`asset-list__card relative`}>
            {loading && (
                <Loader />
                ) }
                {assetsLists?.results?.length && (
                    <>
                        <div className='asset-list__header bodywidth'>
                            <div className='asset-list__row'>
                            <div className='asset-list__cell-one head' onClick={() => requestSort('wellName')}>
                            Well Name{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'wellName' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('api')}>
                                    API{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'api' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('longitude')}>
                                    Lat/Long{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'longitude' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('lastGoodScan')}>
                                    Last communication{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'lastGoodScan' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('operator')}>
                                    Operator{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'operator' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('route_Field')}>
                                    Route/Field{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'route_Field' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('alarms')}>
                                    Alarms{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'alarms' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('installationDate')}>
                                    Run days
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'installationDate' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('runStatus')}>
                                    Status{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'runStatus' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('assetID')}>
                                    Asset GUID{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'assetID' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('companyID')}>
                                    Company ID{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'companyID' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('isSleAsset')}>
                                    Data source
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'isSleAsset' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('pocType')}>
                                    XSPOC – POC Type{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'pocType' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one lastInfluxData' onClick={() => requestSort('lastGoodScan')}>
                                    Last Influx Data Received Date & Time
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'lastGoodScan' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one lastXSPOCData' onClick={() => requestSort('lastXspocScan')}>
                                    Last XSPOC Scan Date & Time
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'lastXspocScan' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('frequency')}>
                                    Frequency{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'frequency' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('motorTemp')}>
                                    Motor temp
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'motorTemp' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('motorCurrent')}>
                                    Motor current
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'motorCurrent' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}{' '}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('casingPressure')}>
                                    Casing pressure{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'casingPressure' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('tubingPressure')}>
                                    Tubing pressure{' '}
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'tubingPressure' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('intakePressure')}>
                                    Intake pressure
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'intakePressure' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('intakeTemperature')}>
                                    Intake temp
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'intakeTemperature' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('powerConsumption')}>
                                    Power consumption
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'powerConsumption' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('incomingHarmonic')}>
                                    Input harmonic
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'incomingHarmonic' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                                <div className='asset-list__cell-one' onClick={() => requestSort('outputHarmonic')}>
                                    Output harmonic
                                    <span className='sort-icon'>
                                        {sortConfig?.key === 'outputHarmonic' ? (
                                            sortConfig.direction === 'asc' ? (
                                                <SortIconUp alt='sort-asc' className='sort-img' />
                                            ) : (
                                                <SortIconDown alt='sort-dsc' className='sort-img' />
                                            )
                                        ) : (
                                            <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`asset-list__table bodywidth ${loading || listLoading ? 'table-opacity' : ''}`} >
                            
                            <div id="assetlistTable" className='asset-list__main-content'>
                                {sortedAssetEventSchedule?.map((event, index) => {
                                  const lastGoodScanDate  = event?.lastGoodScan ? ConvertTimeToLocal(event?.lastGoodScan) : null;
                                  const lastXspocScanDate  = event?.lastXspocScan ? ConvertTimeToLocal(event?.lastXspocScan) : null;
                                  const isLastXspocGreaterThanLastGoodScan = lastGoodScanDate && lastXspocScanDate && lastGoodScanDate < lastXspocScanDate;

                                  const rowClass = isLastXspocGreaterThanLastGoodScan ? 'red-color' : 'white-color';

                                    return (
                                        <div key={index + "wellName"} className={`asset-list__row ${rowClass}`} ref={index + 1 == sortedAssetEventSchedule?.length ? lastDocumentElementRef : null}>
                                            <div

                                                className='asset-list__cell-two'
                                                onClick={() => handleWellName(event.wellName ?? '', event?.assetID ?? '')}
                                            >
                                                {event.wellName ?? '-'}
                                            </div>
                                            <div className='asset-list__cell'>{event.api ?? '-'}</div>
                                            <div className='asset-list__cell'>
                                                {event.latitude}{' '} {event.longitude ?? '-'}
                                            </div>
                                            <div className='asset-list__cell'>{event?.lastGoodScan ? ConvertTimeToLocal(event?.lastGoodScan) : "-"}</div>
                                            <div className='asset-list__cell'>{event.operator ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.route_Field ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.alarms ?? '-'}</div>
                                            <div className='asset-list__cell'>{event?.installationDate ? CalculateRunDays(event?.installationDate) : "-"}</div>
                                            <div className='asset-list__cell'>{event.runStatus ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.assetID ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.companyID ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.isSleAsset !== undefined ? (event.isSleAsset ? "IOT" : "Polling") : '-'}</div>
                                            <div className='asset-list__cell'>{event.pocType ?? '-'}</div>
                                            <div className='asset-list__cell lastInfluxData'>{event?.lastGoodScan ? ConvertTimeToLocal(event?.lastGoodScan) : "-"}</div>
                                            <div className='asset-list__cell lastXSPOCData'>{event?.lastXspocScan ? ConvertTimeToLocal(event?.lastXspocScan) : "-"}</div>
                                            <div className='asset-list__cell center'>{event.frequency ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.motorTemp ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.motorCurrent ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.casingPressure ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.tubingPressure ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.intakePressure ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.intakeTemperature ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.powerConsumption ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.incomingHarmonic ?? '-'}</div>
                                            <div className='asset-list__cell'>{event.outputHarmonic ?? '-'}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )}
                <div style={{ display: (loading || listLoading) || assetsLists?.results?.length ? 'none' : 'block' }}>
                    <NoData heading='No data found' />
                </div>
            </div>
        </div>
    );
};

export default AssetList;

