import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ControllerDetails } from './model/ControllerDetails';
import { GetControllerDetails, GetScheduleSpeedChangeStatus } from './AssetDetailsHeaderService';


interface UserState {
  loading: boolean;
  controllerDetails: ControllerDetails | null;
  error: string | null;
  wellStatus: boolean | undefined | string;
  status: number | null;
  statusLoading: boolean;
}

const initialState: UserState = {
  loading: false,
  controllerDetails: null,
  error: null,
  wellStatus: undefined,
  status: null,
  statusLoading: false,
};

export const fetchControllerDetails = createAsyncThunk('user/getControllerDetails', async ({ userId, nodeId, controllerType }: { userId: string, nodeId: string, controllerType: string }) => {
  const response = await GetControllerDetails(userId, nodeId, controllerType);
  return response;
})

export const fetchScheduleSpeedChangeStatus = createAsyncThunk(
  'user/ScheduleSpeedChangeStatus',
  async ({ wellId }: { wellId: string }) => {
    const response = await GetScheduleSpeedChangeStatus(wellId);
      return response;
  }
);

const assetdetailsHeaderSlice = createSlice({
  name: 'assetdetailsHeader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchControllerDetails.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchControllerDetails.fulfilled, (state, action) => {
      state.controllerDetails = action.payload;
      state.loading = false;
    })
    builder.addCase(fetchControllerDetails.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    })
    builder.addCase(fetchScheduleSpeedChangeStatus.pending, (state) => {
      state.statusLoading = true;
      state.status = null;
    });
    builder.addCase(fetchScheduleSpeedChangeStatus.fulfilled, (state, action) => {
      state.status = action.payload?.data?.status;
      state.statusLoading = false;
    });
    builder.addCase(fetchScheduleSpeedChangeStatus.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch status';
      state.statusLoading = false;
    });
  }
});

export default assetdetailsHeaderSlice.reducer;