import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { HistoryDetailsProps, TimeSeriesChannelDataProps } from './HistoryDetails';
import axios from 'axios';
import { GetAggregation } from '../../../../utilities/CommonFunctions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { fetchAssetTimeseriesChannelsData } from './HistorySlice';
import { addZoomOverlay, syncBulletSpritesOnCursorMove } from '../../../../utilities/generalFunctions';

interface ChartDataProps {
  date: number;
  value: number;
}

interface LineChartProps {
  chartName: string;
  index: number;
  unitOfMeasure?: string;
  chartData: HistoryDetailsProps[] | null;
  colorCombination: any;
  short_UnitOfMeasure: any,
  selectedDate: {
    startDate: Date | null,
    endDate: Date | null
  },
  currentAggregateValue: string;
  filteredValues: string[],
  activeLabels: { [key: string]: boolean }[]
}
const chartsToSync: am5xy.XYChart[] = [];
let syncedCursors: am5xy.XYCursor[] = [];

const LineChart: React.FC<LineChartProps> = ({ chartName, index, chartData, colorCombination, short_UnitOfMeasure, selectedDate, currentAggregateValue, filteredValues, activeLabels }) => {

  const chartId = `chart-${chartName}-${index}-${Math.random()}`;
  const timeSeriesCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const currentAggregation = useRef(currentAggregateValue)
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const aggregateValues = useAppSelector((state) => state?.assetDetail?.xspocAggregateValue)
  const [loading, setLoading] = useState(false)
  const { granularDataLoading, sparkLineFilterData } = useAppSelector((state) => state.history);
  const { trendsFilterData } = useAppSelector((state) => state.trends);
  const prevZoomState = useRef({ start: 0, end: 1 })
  const dispatch = useAppDispatch()
  const chartRef = useRef<am5xy.XYChart>()
  const activeLabelsRef = useRef<{ [key: string]: boolean }[]>([])

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDate.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDate.endDate);


  useEffect(() => {
    activeLabelsRef.current = activeLabels
    if (activeLabels.length > 0) {
      const seriesArray = Array.from(chartRef.current?.series || []);
      activeLabels?.forEach((element: any) => {
        const trendName = Object.keys(element)[0];
        const isVisible = Object.values(element)[0] as boolean;
        const matchedSeries = seriesArray.find(series => series?.get('name') === trendName);
        if (matchedSeries) {
          if (isVisible) {
            matchedSeries.animate({
              key: "opacity",
              to: 1,
              duration: 100, // Duration in milliseconds
              easing: am5.ease.out(am5.ease.cubic) // Easing function for smooth effect
            }).events.on('stopped', function () {
              matchedSeries.set("visible", true);
            })
          } else {
            matchedSeries.animate({
              key: "opacity",
              to: 0,
              duration: 500, // Duration in milliseconds
              easing: am5.ease.out(am5.ease.cubic) // Easing function for smooth effect
            }).events.on('stopped', function () {
              matchedSeries.set("visible", false);
            })

          }
        }
      });
    }
  }, [activeLabels])

  const handleStartEndChange = (targetChart: am5xy.XYChart) => {

    setTimeout(async () => {
      const xAxis = targetChart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>
      if (xAxis) {
        const startTimestamp = xAxis.getPrivate("selectionMin") ?? 0;
        const endTimestamp = xAxis.getPrivate("selectionMax") ?? 0;
        const start = xAxis.get("start") || 0;
        const end = xAxis.get("end") || 1;
        if (start >= 0 || end <= 1) {
          const zoomDirection = (prevZoomState.current.start < start || prevZoomState.current.end > end) ? 'in' : 'out'
          prevZoomState.current = { start: start, end: end }
          const apiData = await getData(new Date(startTimestamp), new Date(endTimestamp), zoomDirection)
          if (Array.isArray(chartsToSync)) {
            am5.array.each(chartsToSync, function (chart) {
              const seriesArray = Array.from(chart.series || []);
              apiData?.forEach((element: any) => {
                const matchedSeries = seriesArray?.find(series => series?.get('name') === element?.trendName);
                if (matchedSeries && Array.isArray(element.dataPoints)) {
                  // Convert and sort data points
                  const convertedArray = element.dataPoints.map((item: any) => ({
                    ...item,
                    date: new Date(item.date.includes('z') || item.date.includes('Z') ? item.date : item.date + 'Z').getTime(),
                  }));
                  const sortedData = convertedArray.sort((a: any, b: any) => a.date - b.date);
                  matchedSeries.data?.setAll?.(sortedData);
                }
              });
            });
          }
        }
      }
    }, 50);

  };

  function debounce(func: any, wait: number) {
    let timeout: any;
    return function (...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedHandleStartEndChange = debounce((targetChart: am5xy.XYChart) => handleStartEndChange(targetChart), 500);

  function syncAxes(targetChart: am5xy.XYChart) {


    const targetAxis = targetChart.xAxes.getIndex(0);

    if (targetAxis && targetAxis._skipSync !== true) {
      const start = targetAxis.get("start");
      const end = targetAxis.get("end");

      am5.array.each(chartsToSync, function (chart) {
        if (chart !== targetChart) {

          const axis = chart.xAxes.getIndex(0);
          if (axis) {
            axis._skipSync = true;
            axis.setAll({
              start: start,
              end: end
            });
            axis._skipSync = false;
          }
        }
        if (chart === targetChart) {
          debouncedHandleStartEndChange(targetChart)
        }
      });
    }
  }

  const getData = async (start: any, end: any, zoomDirection = 'in') => {
    if (timeSeriesCancelTokenSource.current) {
      timeSeriesCancelTokenSource.current.cancel('canceled');
    }
    const { store } = await import('../../../../store');

    const historySparklineDataSet = store.getState().history.historySparklineDataSet;
    timeSeriesCancelTokenSource.current = axios.CancelToken.source();

    const wellName = selectedAsset?.selectedAssetName ?? '';
    const startDate = new Date(start).toISOString();
    const endDate = new Date(end).toISOString();
    const aggregateValue = GetAggregation(new Date(start), new Date(end), aggregateValues)
    if (currentAggregation.current !== aggregateValue) {
      setLoading(true)
      currentAggregation.current = aggregateValue
    }
    const matchedRecord = historySparklineDataSet?.find((item) => item.aggregate === aggregateValue)
    let matchedData = matchedRecord?.data
    if (!matchedData) {
      setLoading(true)
      await dispatch(fetchAssetTimeseriesChannelsData({ wellName: wellName, startDate: startDate, endDate: endDate, channelIds: filteredValues?.toString(), aggregate: aggregateValue, cancelToken: timeSeriesCancelTokenSource.current.token, granularData: true }))
        .unwrap()
        .then((response) => {
          const data = response.data
          matchedData = data
        })
        .catch((error) => {
          console.error('Failed to fetch fluid tab details:', error);
          matchedData = []
        })

    } else {
      if (zoomDirection === 'out' || zoomDirection === 'in') {
        const storedDataStartDate = matchedRecord?.startDate ? new Date(matchedRecord?.startDate) : new Date(0)
        const storedDataEndDate = matchedRecord?.endDate ? new Date(matchedRecord?.endDate) : new Date(0)
        const startDateDifference = Math.abs(storedDataStartDate.getTime() - new Date(startDate).getTime());
        const endDateDifference = Math.abs(storedDataEndDate.getTime() - new Date(endDate).getTime());

        if ((storedDataStartDate > new Date(startDate) && startDateDifference > 1 * 1000) || (storedDataEndDate < new Date(endDate) && endDateDifference > 1 * 1000)) {
          setLoading(true)

          await dispatch(fetchAssetTimeseriesChannelsData({ wellName: wellName, startDate: startDate, endDate: endDate, channelIds: filteredValues?.toString(), aggregate: aggregateValue, cancelToken: timeSeriesCancelTokenSource.current.token, granularData: true }))
            .unwrap()
            .then((response) => {
              const data = response.data
              matchedData = data
            })
            .catch((error) => {
              console.error('Failed to fetch fluid tab details:', error);
              matchedData = []
            })
        }
      }
    }

    setLoading(false)

    const timeSeriesArray: TimeSeriesChannelDataProps[] = []
    matchedData?.map((historyData: HistoryDetailsProps) => {
      const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === historyData?.trendName && filterData?.unitOfMeasure.toLowerCase() !== 'none')

      if (matchedData) {
        const matchedDefaultTrend = trendsFilterData?.find((item: any) => matchedData.paramStandardType?.legacyId.ParamStandardTypesId === item.paramStandardType)
        const timeSeriesData = { ...historyData, channelId: matchedData.channelId, trendName: matchedDefaultTrend ? matchedDefaultTrend.name : matchedData.description, unitOfMeasure: matchedData.unitOfMeasure, short_UnitOfMeasure: matchedData.short_UnitOfMeasure }
        timeSeriesArray.push(timeSeriesData)
      }
    })
    return timeSeriesArray;
  }

  const getValueByKey = (key: string): boolean | undefined => {
    const foundLabel = activeLabelsRef.current?.find((label) => Object.keys(label)[0] === key);
    return foundLabel ? foundLabel[key] : undefined;
  };

  const hideCursors = (opacity: number | undefined, target?: am5xy.XYCursor) => {
    syncedCursors.forEach((cursor) => {
      if (cursor !== target) {
        cursor.set("opacity", opacity ?? 0);
        if (opacity === 0) {
          cursor.set("alwaysShow", false);
        }
      }
    });
  };

  useLayoutEffect(() => {
    if (!chartData) return;
    const root = am5.Root.new(chartId);

    root.setThemes([
      am5themes_Dark.new(root)
    ]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'none',
      wheelY: 'zoomX',
      pinchZoomX: true,
      maxTooltipDistance: -1
    }));

    chartsToSync.push(chart);
    chartRef.current = chart

    addZoomOverlay(chart, root, { text: 'Use CTRL + Scroll to zoom', fontSize: 30, fillOpacity: 0.3, overlayTimeout: 800 });

    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      groupData: false,
      extraMax: 0,
      extraMin: 0,
      strictMinMax: true,
      baseInterval: { timeUnit: 'second', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 100
      })
    }));

    if (selectedDate && selectedDate?.startDate)
      xAxis.set('min', new Date(startDate).getTime())
    if (selectedDate && selectedDate?.endDate)
      xAxis.set('max', new Date(endDate).getTime())

    xAxis.get('renderer').labels.template.set('paddingTop', 15);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.2,
      extraMax: 0,
      extraMin: 0,
      renderer: am5xy.AxisRendererY.new(root, {}),
      width: 40
    }));

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        behavior: 'none',
      }),
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.setAll({
      visible: true,
      stroke: am5.color('#ADD8E6'),
      strokeWidth: 2,
    });

    cursor?.lineY.setAll({
      strokeDasharray: [2, 2],
    });
    syncedCursors.push(cursor);

    xAxis.on("start", () => syncAxes(chart));
    xAxis.on("end", () => syncAxes(chart));

    cursor.on("opacity", hideCursors);
    cursor?.events.on('cursormoved', cursorMoved);
    cursor?.events.on('cursorhidden', hideBullets);

    let previousBulletSprites: any = [];
    function cursorMoved(ev: any) {
      syncedCursors.forEach((cursor) => {
        const chart = cursor.chart;
        if (cursor !== ev.target) {
          cursor.setTimeout(() => {
            cursor.handleMove(ev.target.getPrivate("lastPoint"), true);
            cursor.set("alwaysShow", true);
          }, 100);
        } else {
          cursor.set("alwaysShow", false);
        }

        const cursorPoint = cursor.getPrivate("point");
        const chartWidth = chart?.width();
        chart?.series.each((series) => {
          const tooltip = series.get("tooltip");
          const toolTipPosition = tooltip?.get("pointerOrientation");

          if (cursorPoint && chartWidth) {
            const xPos = cursorPoint.x;
            if (xPos < chartWidth / 3) {
              if (toolTipPosition !== "left") {
                tooltip?.set("pointerOrientation", "left");
                tooltip?.set("dx", 15);
              }
            } else {
              if (toolTipPosition !== "right") {
                tooltip?.set("pointerOrientation", "right");
                tooltip?.set("dx", -15);
              }
            }
          }
        });
      });
    }


    cursor.events.on("cursormoved", () => syncBulletSpritesOnCursorMove(chart, chartsToSync, previousBulletSprites));

    function hideBullets() {
      for (let i = 0; i < previousBulletSprites.length; i++) {
        previousBulletSprites[i].unhover();
        previousBulletSprites[i].set('visible', false); // Hide bullets
      }
      previousBulletSprites = [];

      yAxis?.axisRanges?.each((range) => {
        range.get("label")?.set("visible", false)
        range.get("grid")?.set("strokeOpacity", 0)
        range.get("axisFill")?.set("fillOpacity", 0)
      })
    }


    function createSeries(
      name: string,
      color: string,
      data: ChartDataProps[],
    ) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            pointerOrientation: 'left',
            dy: 0,
            dx: 15,
          }),
          stroke: am5.color(color),
          snapTooltip: true,

        }),
      );
      const tooltip = series.get("tooltip");
      tooltip?.get("background")?.setAll({
        fill: am5.color("#001023"),
        strokeOpacity: 0
      });

      series.get("tooltip")?.label?.adapters.add("html", function () {
        let updatedHTML = `<div style="width:auto;background:#001023;border:1px solid #4A5463;border-radius:5px;display:flex;flex-direction:column;font-size: 14px;color: #F7F9F9;font-family: 'Mulish';font-weight: 400">`;
        let showDateTime = true;

        chart.series.each(function (series) {
          const tooltipDataItem = series.get("tooltipDataItem");

          if (tooltipDataItem) {
            if (showDateTime) {
              const valueX = tooltipDataItem.get("valueX");
              if (valueX != null) {
                const date = new Date(valueX);
                const dateOptions = { weekday: 'long', month: 'short', day: 'numeric' } as const;
                const formattedDate = date.toLocaleDateString(undefined, dateOptions);
                const timeOptions: any = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
                const datetime = `${formattedDate}, ${formattedTime}`;

                updatedHTML += `<div style="border-bottom: 1px solid #4A5463; padding: 10px;">${datetime}</div>`;
                showDateTime = false;
              }
            }

            const valueY = tooltipDataItem.get("valueY");
            const valueYText = valueY == null ? "" : Number(valueY).toFixed(3);
            const seriesName = series.get("name") || "Unnamed Series";
            const seriesColor = series.get("stroke")?.toString() || "#000000";

            if (getValueByKey(seriesName)) {
              updatedHTML += `
                <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px; gap: 20px;">
                  <div style="display: flex; align-items: center;">
                    <span style="display: inline-block;  height: 16px; background-color: ${seriesColor}; border: 1px solid ${seriesColor}; margin-right: 8px;"></span>
                    <span>${seriesName}</span>
                  </div>
                  <div>${valueYText} ${short_UnitOfMeasure}</div>
                </div>
              `;
            }
          }
        });
        updatedHTML += "</div>";

        return updatedHTML;
      });



      series.strokes.template.set('strokeWidth', 2);


      series.bullets.push(() => {
        const circle = am5.Circle.new(root, {
          radius: 0,
          fill: am5.color('#0000'),
          stroke: am5.color('#fff'),
          strokeWidth: 5,
          visible: true,
        });

        circle.states.create('hover', {
          radius: 7,
        });

        return am5.Bullet.new(root, {
          locationX: 0.5,
          sprite: circle,
        });
      });
      series.data.setAll(data);
    }

    chartData?.forEach((element) => {
      const data = element?.dataPoints && element?.dataPoints?.map((chart) => {
        return { date: new Date(chart?.date.includes('Z') || chart?.date.includes('Z') ? chart?.date : chart?.date + 'Z').getTime(), value: Number(chart?.value) }
      });

      const sortedData = data?.sort((a, b) => a.date - b.date)
      const colordata = colorCombination[element.trendName] ? colorCombination[element.trendName] : '#60BFDA';
      createSeries(
        element?.trendName,
        colordata,
        sortedData ?? [],
      );
    });

    return () => {
      root.dispose();
      syncedCursors = []
      chartsToSync.length = 0;

    };
  }, [chartData, filteredValues]);

  // return <div id={chartId} style={{ width: "100%", height: "200px" }} className={`sparkline-chart ${chartData ? '' : 'center-content'}`}>
  //   {!chartData && <p>No data</p>}
  // </div>;
  return (
    <div id={chartId} style={{
      width: "100%",
      height: "200px",
      opacity: loading || granularDataLoading ? 0.5 : 1,
      pointerEvents: loading || granularDataLoading ? "none" : "auto",
      position: 'relative'

    }} className={`sparkline-chart ${chartData ? '' : 'center-content'}`}>
      {loading || granularDataLoading ? (
        <div className='trend-loader flex items-center justify-center w-100'>
          <Loader />
        </div>
      ) : (!chartData && <NoData heading='No data found' />)}

    </div>
  )
};

export default LineChart;